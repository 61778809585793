<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <v-card class="mx-auto"
    >
      <!-- <v-img
        :src="require(`@/assets/promotions/${value.hero}`)"
        height="100%"
      ></v-img> -->
      <img v-bind:src="utils.imageWait(value.picture_base64)" width="100%" height="100%" @click.stop="viewPromotions(value)">
      <v-card-title>{{value.promotions_name}}</v-card-title>
      <v-card-text class="pb-0 mb-0" v-html="value.promotions_detail.substring(0, value.promotions_detail.indexOf('</p>')).substring(0, 40)"></v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-1 mt-1">
        <span class="caption">{{ $t('MeeTeeMeeNgern1.view') }} {{value.view_count}} คน</span>
        <v-spacer></v-spacer>
        <v-chip
          class="text-uppercase ma-0"
          color="pink"
          dark
          label
          small
          @click.stop="viewPromotions(value)"
        >
          <span class="body-1">{{ $t('MeeTeeMeeNgern1.readMore') }}</span>
        </v-chip>
      </v-card-actions>
    </v-card>
    <!-- </base-card> -->
  </v-col>
</template>

<script>
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
      }
    },
    methods: {
      viewPromotions (value) {
        this.$router.push({ name: 'Promotion', params: { propValue: value } })
      },
    }
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
